import React from 'react'

import { Pro3labPageLayout } from '@/components/pro3lab/pro3labPageLayout'
import { tempNewsList } from '@/constants/news/pro3lab'
import { Pro3labHead } from '@/components/pro3lab/pro3labHead'
import { NewsList } from '@/components/common/news'

export default function Pro3labNews(): JSX.Element {
  return (
    <>
      <Pro3labHead title="株式会社Pro3Lab（プロスリーラボ）: 『エンジニアリングによるテコの力』で、世の中を変革する『大義あるプロダクト』を創る" url="/group/pro3lab" />
      <Pro3labPageLayout
        title="お知らせ"
        subTitle="NEWS"
        breadcrumbs="GROUP/Pro3Lab/NEWS"
        breadcrumbsPath="/group/pro3lab/news"
      >
        <NewsList newsList={tempNewsList} isPage />
      </Pro3labPageLayout>
    </>
  )
}
