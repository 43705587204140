import { NewsType } from '@/typedir/types'

export const tempNewsList: NewsType[] = [
  {
    date: '2022.04.18',
    text: 'Relicホールディングス、業界初の完全レベニューシェア特化型でwebアプリなどの新規ソフトウェア開発を行う「株式会社Pro3 Lab」を設立　　',
    url: 'https://prtimes.jp/main/html/rd/p/000000113.000016318.html'
  }
  // {date: "2099.09.28", text: "テスト", url: "https://prtimes.jp/main/html/rd/p/000000085.000016318.html"}
]
