import React from 'react'
import { Helmet } from 'react-helmet'
import '@/styles/scalehack/styleCo.scss'

import favicon from '@/images/pro3lab/favicon.ico'
import appleIcon from '@/images/pro3lab/apple-touch-icon.png'

type Props = {
  title: string
  url: string
}

export const Pro3labHead = (props: Props): JSX.Element => {
  const { title, url } = props

  return (
    <Helmet>
      <html lang="ja" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <title>{title}</title>
      <meta
        name="description"
        content="Pro3Labでは、「『エンジニアリングによるテコの力』で、世の中を変革する『大義あるプロダクト』を創る」をコンセプトに、エンジニアが開発工数を先に投資し、将来の売上や収益から投資コストを回収することで、パートナー様が初期開発費用を抑え、新たな開発に挑戦しやすくする「完全成果報酬型エンジニアリング」モデルを提供することで、大志あるパートナー様と共にwebアプリなどのソフトウェアを共同開発することを実現します。"
      />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={`https://relic-holdings.co.jp/${url}`} />
      <meta
        property="og:image"
        content="https://relic-holdings.co.jp/OGP_1200*630_Pro3Lab.png"
      />
      <meta
        property="og:description"
        content="Pro3Labでは、「『エンジニアリングによるテコの力』で、世の中を変革する『大義あるプロダクト』を創る」をコンセプトに、エンジニアが開発工数を先に投資し、将来の売上や収益から投資コストを回収することで、パートナー様が初期開発費用を抑え、新たな開発に挑戦しやすくする「完全成果報酬型エンジニアリング」モデルを提供することで、大志あるパートナー様と共にwebアプリなどのソフトウェアを共同開発することを実現します。"
      />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="ja_JP" />
      <meta property="og:site_name" content={title} />
      <meta name="twitter:card" content="summary_large_image" />
      <link rel="icon" href={favicon} />
      <link
        rel="apple-touch-icon-precomposed"
        href={appleIcon}
        sizes="180x180"
      />
    </Helmet>
  )
}
